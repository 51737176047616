import { MsalInterceptorConfiguration } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { InteractionType } from '@azure/msal-browser';

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  /** MS Graph is protected by default */
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  /** Health check service is not protected */
  protectedResourceMap.set(`${environment.apiBasePath}/v1/health-check`, null);

  /** Below the protected resources */
  protectedResourceMap.set(`${environment.apiBasePath}/*`, [`${environment.azureADApiAppURI}/Endpoint.Access`]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
