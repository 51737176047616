import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { DialogService } from './dialog.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(
    private readonly ngZone: NgZone,
    private readonly dialogService: DialogService
  ) {}

  /**
   * Handles errors and exceptions that were not handled locally.
   *
   * @param error caught by Angular
   */
  public handleError(error: unknown): void {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
    Sentry.captureException((error as any).originalError || error);

    /**
     * Not handled network errors will end up here with the HttpErrorResponse
     * object. We need to transform that into a normal error when reporting to Sentry.
     */
    if (error instanceof HttpErrorResponse) {
      Sentry.captureMessage(`Error response is unhandled for ${error.url}.`, {
        level: 'error',
        extra: {
          errorResponsePayload: error.error,
          message: error.message,
        },
      });
    }

    console.error('An unhandled error happened in the application!');
    console.error(error);

    /**
     * By default, Error handler runs outside of zone. In order of the change detection to work
     * we have to open dialog in zone.
     * See: https://github.com/angular/components/issues/13640#issuecomment-430415948
     * */
    this.ngZone.run(() => {
      this.dialogService.openErrorDialog(error);
    });
  }
}
