<div
  *ngIf="frontendEnvironment !== 'production'"
  class="environment-marker-container"
  [ngClass]="'environment-marker-container-' + frontendEnvironment"
>
  <div class="environment-marker">
    {{ frontendEnvironment | uppercase }}
  </div>
</div>
<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav
    class="sidenav"
    fixedInViewport
    [disableClose]="true"
    [ngClass]="{ 'sidenav-expanded': isExpanded }"
    [attr.role]="'navigation'"
    [mode]="'side'"
    [opened]="true"
  >
    <mat-toolbar (click)="isExpanded = !isExpanded" color="primary" class="sidenav-header">
      <h2 *ngIf="isExpanded">Sinergy Platform</h2>
      <button mat-icon-button>
        <mat-icon *ngIf="isExpanded" matListItemIcon>chevron_left</mat-icon>
        <mat-icon *ngIf="!isExpanded" matListItemIcon>chevron_right</mat-icon>
      </button>
    </mat-toolbar>

    <ngx-simplebar class="simplebar" [ngClass]="isExpanded ? 'simplebar-opened' : 'simplebar-closed'">
      <mat-nav-list>
        <ng-container *ngFor="let menuItem of menuItems; index as i">
          <ng-container
            [ngTemplateOutlet]="childMenuItem"
            [ngTemplateOutletContext]="{ menuItem: menuItem }"
          ></ng-container>
        </ng-container>
      </mat-nav-list>
    </ngx-simplebar>
    <div class="footer-container">
      <!-- Tenant selector -->
      <mat-form-field
        class="tenant-selector"
        [ngClass]="isExpanded ? 'tenant-selector-opened' : 'tenant-selector-closed'"
      >
        <mat-select
          [value]="(selectedTenant | async)?.identifier"
          [disabled]="(tenantList | async)?.length === 1"
          [matTooltip]="(selectedTenant | async)?.name ?? ''"
          matTooltipPosition="right"
          [matTooltipDisabled]="isExpanded"
        >
          <mat-option
            *ngFor="let tenant of tenantList | async"
            (click)="changeTenant(tenant)"
            [value]="tenant.identifier"
            >{{ tenant.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <div
        *ngIf="isExpanded"
        class="sidenav-list-item sidenav-user-info"
        [matTooltip]="userDisplayName"
        matTooltipPosition="right"
        [matTooltipDisabled]="isExpanded"
      >
        <mat-icon class="account-circle-icon" matListItemIcon>account_circle</mat-icon>
        <div class="sidenav-list-item-title">{{ userDisplayName }}</div>
        <button (click)="signOut()" mat-icon-button class="sidenav-logout">
          <mat-icon>logout</mat-icon>
        </button>
      </div>
      <div
        *ngIf="!isExpanded"
        class="sidenav-list-item sidenav-user-info"
        [matTooltip]="userDisplayName"
        matTooltipPosition="right"
        [matTooltipDisabled]="isExpanded"
      >
        <mat-icon matListItemIcon>account_circle</mat-icon>
      </div>
      <div class="version" [matTooltip]="'FE: v' + frontendVersion + '; BE: v' + backendVersion">
        <span>FE: v{{ frontendVersion }}; </span>
        <span>BE: v{{ backendVersion }}</span>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-maintenance-banner></app-maintenance-banner>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #childMenuItem let-menuItem="menuItem">
  <mat-list-item
    class="sidenav-list-item"
    [routerLink]="menuItem.path + '/' + submenuItem.path"
    *ngFor="let submenuItem of menuItem.children"
    routerLinkActive="is-active"
    [routerLinkActiveOptions]="{ exact: true }"
    (click)="activeMenuItem = submenuItem"
    [matTooltip]="submenuItem.title"
    matTooltipPosition="right"
    [matTooltipDisabled]="isExpanded"
  >
    <mat-icon matListItemIcon>{{ submenuItem.icon }}</mat-icon>
    <span *ngIf="isExpanded" class="sidenav-list-item-title" matListItemTitle>
      {{ submenuItem.title }}
    </span>
  </mat-list-item>
  <mat-divider></mat-divider>
</ng-template>

<ng-template #childMenuItem> </ng-template>
