import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

Sentry.init({
  enabled: environment.reportErrors,
  environment: environment.environment,
  dsn: 'https://a621efcfe4d748088f0eb6477f37779c@o1180360.ingest.sentry.io/6314837',
});

if (environment.enableAngularProdMode) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
